import moment from 'moment';
import React, { FocusEvent, FormEvent, useEffect, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { DEFAULT_DATE_FORMAT } from '../../constants';
import { useControlledBlur } from '../../hooks';
import { Container, DatePickerContainer, StyledCalendarIcon, StyledInput } from './DatePicker.styles';

const INPUT_CLASS = 'date-input';
const DATE_PICKER_CLASS = 'react-datepicker';
const DATE_PICKER_YEAR_DROPDOWN_CLASS = '.react-datepicker__year-dropdown';

interface Props {
  date: Date;
  onDateChange: (date: Date | null) => void;
  format?: string;
  useYearDropdown?: boolean;
  placeholder?: string;
  label?: string;
  ariaLabel?: string;
  error?: string;
  maxDate?: Date;
}

export const DatePicker = ({
  date,
  onDateChange,
  format = 'MM/DD/yyyy',
  useYearDropdown,
  placeholder = DEFAULT_DATE_FORMAT,
  label,
  ariaLabel,
  error,
  maxDate = new Date('12/31/9999'),
}: Props) => {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [inputValue, setInputValue] = useState(() => (date ? moment(date).format(format) : ''));

  useEffect(() => setInputValue(date ? moment(date).format(format) : ''), [date]);

  useControlledBlur({
    cssSelectorsToIgnore: [`.${DATE_PICKER_CLASS}`, `.${INPUT_CLASS}`, DATE_PICKER_YEAR_DROPDOWN_CLASS],
    onBlur: () => setShowDatePicker(false),
  });

  const validateDate = (date: moment.Moment) => {
    return date.isValid() && !date.isAfter(moment(maxDate));
  };

  return (
    <Container>
      <StyledInput
        className={INPUT_CLASS}
        label={label}
        aria-label={ariaLabel ?? t('components.datePicker.inputLabel')}
        iconLeft={<StyledCalendarIcon />}
        placeholder={placeholder}
        value={inputValue}
        error={error}
        onChange={({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => setInputValue(value)}
        onFocus={() => setShowDatePicker(true)}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          const date = moment(e.currentTarget.value);
          const validated = validateDate(date);
          setInputValue(validated ? date.format(format) : '');
          onDateChange(validated ? date.toDate() : null);
        }}
      />
      {showDatePicker && (
        <DatePickerPopup
          dateFormat={DEFAULT_DATE_FORMAT}
          showYearDropdown={useYearDropdown}
          dateFormatCalendar={useYearDropdown ? 'MMMM' : undefined}
          selected={date}
          onChange={(date) => {
            setShowDatePicker(false);
            setInputValue(moment(date).format(format));
            onDateChange(date);
          }}
          maxDate={maxDate}
          inline
          dropdownMode={'select'}
        />
      )}
    </Container>
  );
};

export const DatePickerPopup = (props: ReactDatePickerProps) => (
  <DatePickerContainer>
    <ReactDatePicker dateFormat={DEFAULT_DATE_FORMAT} inline {...props} />
  </DatePickerContainer>
);
