import React, { ComponentType, Key, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useConditionalEventListener } from '../../hooks/useConditionalEventListener';
import { PopoverPlacement } from '../PopoverButton/PopoverButton.styles';
import {
  DropdownContainer,
  DropdownPortal,
  DropdownRow,
  DropdownText,
  DropdownTitle,
  DropdownTrigger,
  PlaceholderText,
  SelectedTextContainer,
  StyledChevronIcon,
} from './Dropdown.styles';

export interface DropdownProps<T, U extends Key> {
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  placement?: PopoverPlacement;
  items: ReadonlyArray<T>;
  selectedKey: U;
  keySelector: (item: T) => U;
  selectionComponent?: ComponentType<{ item: T }>;
  itemComponent?: ComponentType<{ item: T; selected: boolean }>;
  onSelectionChange: (selection: U) => void;
  textSelector?: (item: T) => string;
  className?: string;
}

const Dropdown = <T, U extends Key>({
  title,
  placeholder,
  disabled,
  placement = PopoverPlacement.bottom,
  items,
  selectedKey,
  keySelector,
  onSelectionChange,
  selectionComponent: Selection,
  itemComponent: Item,
  textSelector,
  className,
}: DropdownProps<T, U>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    triggerRef.current.focus();
  };

  const handleSelectionChange = (key: U) => {
    onSelectionChange(key);
    closeDropdown();
  };

  const handleMouseDown = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!triggerRef?.current?.contains(target) && !contentRef?.current?.contains(target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleTab = () => {
    closeDropdown();
  };

  const handleEscape = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    closeDropdown();
  };

  const getFocusableElements = () => {
    return contentRef.current?.querySelectorAll(
      'a[href], button, input:not([type="hidden"]):not([disabled]), textarea, select, details, [tabindex]:not([tabindex="-1"])',
    );
  };

  const handleArrowKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    } else {
      const focusableElements = getFocusableElements();
      for (let i = 0; i < focusableElements?.length; i++) {
        if (focusableElements[i] === e.target) {
          const nextPos = e.key === 'ArrowUp' ? i - 1 : i + 1;
          if (nextPos >= 0 && nextPos < focusableElements?.length) {
            const nextItem = focusableElements[nextPos] as HTMLElement;
            nextItem?.focus();
            nextItem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'Tab':
        handleTab();
        break;
      case 'Escape':
        handleEscape(e);
        break;
      case 'ArrowUp':
        handleArrowKey(e);
        break;
      case 'ArrowDown':
        handleArrowKey(e);
        break;
    }
  };

  useConditionalEventListener(isDropdownOpen, 'scroll', () => setIsDropdownOpen(false));
  useConditionalEventListener(isDropdownOpen, 'mousedown', handleMouseDown);

  const DropdownItems = useMemo(() => {
    return items.map((item) => {
      const key = keySelector(item);
      return (
        <DropdownRow key={key} onClick={() => handleSelectionChange(key)} selected={selectedKey === key}>
          {Item ? (
            <Item item={item} selected={selectedKey === key} />
          ) : (
            <DropdownText>{textSelector?.(item)}</DropdownText>
          )}
        </DropdownRow>
      );
    });
  }, [items, selectedKey, keySelector, textSelector, handleSelectionChange]);

  const selectedItem = items.find((item) => keySelector(item) === selectedKey);
  return (
    <DropdownContainer onKeyDown={handleKeyDown} className={className}>
      {title && <DropdownTitle>{title}</DropdownTitle>}
      <DropdownTrigger ref={triggerRef} disabled={disabled} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {Selection ? (
          <Selection item={selectedItem} />
        ) : (
          <>
            <SelectedTextContainer>
              {selectedItem ? textSelector?.(selectedItem) : <PlaceholderText>{placeholder}</PlaceholderText>}
            </SelectedTextContainer>
            <StyledChevronIcon />
          </>
        )}
      </DropdownTrigger>
      <DropdownPortal isOpen={isDropdownOpen} ref={contentRef} triggerRef={triggerRef} placement={placement}>
        {DropdownItems}
      </DropdownPortal>
    </DropdownContainer>
  );
};

export default styled(Dropdown)`` as typeof Dropdown;
